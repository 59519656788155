var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',[_c('div',{staticClass:"footer b1"},[_c('div',{staticClass:"footer-box"},[_c('div',{staticClass:"footer-box_link"},_vm._l((_vm.menuList),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"footer-box_link__title"},[_c('p',[_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(item.titleEn))])]),_c('ul',_vm._l((item.list),function(itemToo,indexToo){return _c('li',{key:indexToo,on:{"click":function($event){return _vm.goRouteType(
                  itemToo.type,
                  itemToo.link,
                  itemToo.flag,
                  itemToo.isToken
                )}}},[_c('p',[_vm._v(_vm._s(itemToo.name))]),(_vm.$base.isNull(itemToo.icon))?_c('span',[_vm._v(_vm._s(itemToo.en))]):[_c('div',{staticClass:"icon"},[_c('i',[_c('el-image',{attrs:{"src":itemToo.icon,"fit":"fill"}})],1),(!_vm.$base.isNull(itemToo.img))?_c('b',[_c('el-image',{attrs:{"src":itemToo.img,"fit":"fill"}})],1):_vm._e()])]],2)}),0)])}),0)])]),_c('div',{staticClass:"footer b2"},[_c('div',{staticClass:"footer-box"},[_c('div',{staticClass:"footer-box_text"},[_c('span',[_vm._v("©2019 重庆三峡绣手工艺品有限公司保留所有权利")]),_c('a',{on:{"click":function($event){return _vm.goRoute('http://beian.miit.gov.cn/ ', true)}}},[_vm._v("渝ICP备 14009104号-3")]),_c('p',{on:{"click":function($event){return _vm.goRoute(
              'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010102000835 ',
              true
            )}}},[_vm._v("  ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }