
import {Component, Provide, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from "vuex";
import Service from "@/components/Service.vue";
@Component({
  components: {
    Service
  },
  computed: {
    ...mapGetters(["shoppingCartListTotal","shoppingCartList","serviceUnread"])
  }
})
export default class IconRight extends Vue {
  @Provide() iconList:Array<{
    id:number,
    name:string,
    link:string,
    icon:string,
    opacity:number,
  }> = [
    {id:1,name:"客服",icon:"Service",opacity:1,link:''},
    {id:2,name:"购物车",icon:"ShoppingCart",opacity:1,link:'shoppingCart'},
    {id:3,name:"个人中心",icon:"User",opacity:1,link:'account'},
    {id:4,name:"手机APP",icon:"Phone",opacity:1,link:''},
    {id:5,name:"回到顶部",icon:"huidaodingbu",opacity:0,link:''},
  ]
  @Provide() iconOpacity:number = 0;
  @Provide() topItemIndex:number = 0;
  @Provide() topItem:Array<{
    id?:number,
    name?:string,
    icon?:string,
    opacity?:number,
  }> = [];

  @Provide() scrollTrigger:boolean = false
  @Provide() scrollTime:any = null


  mounted(){
    const _this:any = this;


    _this.topItem = _this.iconList.filter((item:any) => item.id == 5)[0]
    _this.topItemIndex = _this.iconList.findIndex((item:any) => item.id == 5)
    window.addEventListener('scroll',_this.handleScroll)
    // window.addEventListener("DOMMouseScroll",_this.mouseScroll,false)||window.addEventListener('mousewheel',_this.mouseScroll , true)
    window.addEventListener('mousewheel',_this.mouseScroll , true)
  }
  /**
   * @Author HS
   * @Date 2021/7/8 3:26 下午
   * @Description:
   * @Params: { number ： id - true } [id]
   * @Return: null
  */
  menuFn(id:number){
    const _this:any = this;
    switch (id) {
      case 1:
        if(_this.$base.isLogin()){
          const path = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path)
          _this.$base.goRouter(path)
          return
        }
        _this.$store.commit('SET_SERVICESHOW',true)
        _this.$store.commit('SET_UNREAD',0);
        _this.$store.commit('REMOVE_CUSTOMIZEINFO');
        break;
      case 2:
        if(_this.$base.isLogin()){
          const path = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path,'shoppingCart')
          _this.$base.goRouter(path)
          return
        }
        _this.$base.goRouter(_this.$IndexConfig.shoppingCart.path)
        break;
      case 3:
        if(_this.$base.isLogin()){
          const path = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path,'account')
          _this.$base.goRouter(path)
          return
        }
        _this.$base.goRouter(_this.$MineConfig.account.path)
        break;
      case 4:
        _this.$store.commit('SET_APPSHOW',true)
        break;
      case 5:
        _this.backtop()
        break;
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/23 4:26 下午
   * @Description: 客服关闭回调
   * @Params: null
   * @Return: null
  */
  serviceHideCallbackFn(){
    const _this:any = this;
    _this.$store.commit('SET_SERVICESHOW',false)
  }


  /**
   * @Author HS
   * @Date 2021/7/16 9:34 上午
   * @Description: 阻止回到顶部
   * @Params: null
   * @Return: null
  */
  mouseScroll(){
    const _this:any = this;
    clearTimeout(_this.scrollTime);
    _this.scrollTrigger = false;
  }

  /**
   * @Author HS
   * @Date 2021/7/8 3:24 下午
   * @Description: 监听滚动
   * @Params: null
   * @Return: null
  */
  handleScroll(){
    const _this:any = this;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
    let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
    _this.scrollTop = scrollTop;
    if (scrollTop > ((clientHeight / 2) - 92)) {
      _this.showTop = true;
    } else {
      _this.showTop = false;
    }

    if(scrollTop > 500){
      _this.topItem.opacity = 1
      _this.iconList[_this.topItemIndex] = _this.topItem
      return
    }
    _this.iconList[_this.topItemIndex].opacity = 0
  }

  /**
   * @Author HS
   * @Date 2021/7/8 3:24 下午
   * @Description: 返回顶部
   * @Params: null
   * @Return: null
  */
  backtop() {
    const _this:any = this;
    // 防止用户频繁点击返回顶部按钮，待返回顶部成功后设置scrollTrigger为初始值
    if (_this.scrollTrigger) {
      return;
    }
    let top = document.documentElement.scrollTop || document.body.scrollTop
    // 实现滚动效果
    smoothUp()
    function smoothUp(){
      _this.scrollTrigger = true
      document.body.scrollTop = document.documentElement.scrollTop = top -= 600
      if(top > 0){
        _this.scrollTime = setTimeout(smoothUp,10)
      }
      else{
        clearTimeout(_this.scrollTime)
        _this.scrollTrigger = false
      }
    }
  }





}
