
import { Component, Provide, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
@Component({
  components: {},
  computed: {
    ...mapGetters(["userInfo", "shoppingCartListTotal", "shoppingCartList"])
  }
})
export default class Header extends Vue {
  @Provide() navType: number = 0;
  @Provide() navIndex: number = 0;
  @Provide() iLeft: number = 0;
  @Provide() searchId: string = "-1";
  @Provide() search: string = "";
  @Provide() searchFocus: boolean = false;
  @Provide() searcHeight: number = 0;
  @Provide() searcTime: any = null;
  @Provide() searchIndex: number = 0;
  @Provide() historySearchList: Array<string> = [];
  @Provide() shopHeight: number = 0;
  @Provide() shoppingCartListPrice: number = 0;
  @Provide() searchBoolean: boolean = false;
  @Provide() searchFlag: boolean = false;
  @Provide() opacity: number = 1;

  @Watch("searchBoolean", { immediate: true, deep: true })
  private searchData(valValue: boolean, oldValue: boolean): void {
    const _this: any = this;
    if (valValue) {
      _this.search = "";
    }
  }

  @Watch("shoppingCartList", { immediate: false, deep: false })
  private shoppingCartListChange(): void {
    const _this: any = this;
    if (_this.$base.isNull(_this.shoppingCartList)) return;
    let { cartPrice, cartNum } = _this.shoppingCartList[0];
    _this.shoppingCartListPrice = _this.$base.accMul(cartPrice, cartNum);
  }

  @Watch("userInfo", { immediate: true, deep: true })
  private iDataChange(): void {
    const _this: any = this;
  }

  @Watch("$route", { immediate: true, deep: false })
  routechange(to: any) {
    const _this: any = this;
    const currentRoute = to.path;
    if (currentRoute == "/Pay") {
      _this.navType = 1;
      return;
    }
    console.log(_this.$route.query);
    let getType = '';
    if (Object.keys(_this.$route.query).length > 0) {
      console.log(_this.$route.query.type);
      if (_this.$route.query.type != undefined) {
        getType = _this.$decryptBy(_this.$route.query.type);
      }
    }
    console.log(getType);
    _this.navType = 0;
    const ids = ["-1", "1", "0"];
    const parameter = to.query.s;
    let id = to.query.i;
    if (!_this.$base.isNull(parameter)) {
      _this.search = _this.$decryptBy(parameter);
    }

    if (!_this.$base.isNull(id)) {
      id = _this.$decryptBy(id);
      _this.searchIndex = ids.findIndex((item: string) => item == id);
    }
    console.log(top);
    console.log(currentRoute);
    if (getType == "1") {
      _this.navIndex = 1;
      let a = setTimeout(() => {
        _this.iLeft = 128;
        clearTimeout(a);
      }, 1);
      return;
    }
    if (getType == "0") {
      _this.navIndex = 2;
      let a = setTimeout(() => {
        _this.iLeft = 234;
        clearTimeout(a);
      }, 1);
      return;
    }
    if (currentRoute.indexOf("/EmbroideryHome") > -1) {
      _this.navIndex = 1;
      let a = setTimeout(() => {
        _this.iLeft = 128;
        clearTimeout(a);
      }, 1);
      return;
    }
    if (currentRoute.indexOf("/WarmLife") > -1) {
      _this.navIndex = 2;
      let a = setTimeout(() => {
        _this.iLeft = 234;
        clearTimeout(a);
      }, 1);
      return;
    }
    if (currentRoute == "/Guide" || currentRoute == "/guide") {
      _this.navIndex = 3;
      _this.iLeft = 340;
      return;
    }
    _this.navIndex = 0;
    return;
  }

  mounted() {
    const _this: any = this;
    if (!_this.$base.isNull(localStorage.getItem("_HISTORYSEARCHLIST"))) {
      const slist: string = _this.$getDecryptBy("_HISTORYSEARCHLIST");
      _this.historySearchList = JSON.parse(slist);
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/1 11:46 上午
   * @Description: 导航切换
   * @Params: { number ： iType - true } [类型：0-首页 1-储绣家居 2-小家碧玉 3-高级定制]
   * @Return: null
   */
  navChangen(iType: number) {
    const _this: any = this;
    switch (iType) {
      case 0:
        _this.navIndex = 0;
        _this.iLeft = 0;
        _this.$base.goRouter(_this.$IndexConfig.home.path);
        break;
      case 1:
        _this.navIndex = 1;
        let a = setTimeout(() => {
          _this.iLeft = 124;
          clearTimeout(a);
        }, 1);
        _this.$base.goRouter(_this.$IndexConfig.embroideryHome.path);
        break;
      case 2:
        _this.navIndex = 2;
        let b = setTimeout(() => {
          _this.iLeft = 224;
          clearTimeout(b);
        }, 1);
        _this.$base.goRouter(_this.$IndexConfig.warmLife.path);
        break;
      case 3:
        _this.navIndex = 0;
        _this.iLeft = 322;
        window.open(_this.$IndexConfig.guide.path, "_blank");
        break;
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/1 4:00 下午
   * @Description: 搜索框获得焦点
   * @Params: null
   * @Return: null
   */
  searchFocusCallback() {
    const _this: any = this;
    const defaultHeight: number = 40;
    // _this.searcHeight = defaultHeight
    _this.searcHeight = 40 * _this.historySearchList.length;
    _this.searchFocus = true;
  }
  /**
   * @Author HS
   * @Date 2021/7/1 4:00 下午
   * @Description: 搜索框获失去得焦点
   * @Params: null
   * @Return: null
   */
  searchBlurCallback() {
    const _this: any = this;
    _this.searchFlag = true;
    _this.searcTime = setTimeout(() => {
      _this.searcHeight = 0;
      _this.searchFocus = false;
      clearTimeout(_this.searcTime);
    }, 200);
    setTimeout(() => {
      _this.searchBoolean = false;
      _this.searchFlag = false;
    }, 600);
  }
  /**
   * @Author HS
   * @Date 2021/7/1 4:22 下午
   * @Description: 搜索
   * @Params: null
   * @Return: null
   */

  searchFn(val: string) {
    const _this: any = this;
    if (!_this.$base.isNull(val)) {
      _this.searchBoolean = true;
      _this.searchFocus = true;
    } else {
      if (_this.$base.isNull(_this.search)) return;

      let _falg: Array<string> = _this.historySearchList.filter(
        (item: string) => item == _this.search
      );
      if (_falg.length > 0) {
        _this.goSearch(_this.search);
        return;
      }
      let arr: Array<string> = [_this.search, ..._this.historySearchList];
      if (arr.length > 6) {
        arr = arr.slice(0, 6);
      }
      _this.historySearchList = [...arr];
      _this.searcHeight = 40 * _this.historySearchList.length;
      _this.$setEncryptBy(JSON.stringify(arr), "_HISTORYSEARCHLIST");
      _this.goSearch(_this.search);
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/1 5:11 下午
   * @Description: 阻止搜索内容回收
   * @Params: null
   * @Return: null
   */
  overSearchBoxStop() {
    const _this: any = this;
    clearTimeout(_this.searcTime);
  }

  /**
   * @Author HS
   * @Date 2021/7/8 4:19 下午
   * @Description: 搜索框回收
   * @Params: null
   * @Return: null
   */
  searchBoxStop() {
    const _this: any = this;

    if (_this.searchFocus) return;
    clearTimeout(_this.searcTime);
    _this.searcTime = setTimeout(() => {
      _this.searcHeight = 0;
      clearTimeout(_this.searcTime);
    }, 3000);
  }

  /**
   * @Author HS
   * @Date 2021/7/9 10:37 上午
   * @Description: 选择搜索类型
   * @Params: { number ： index - true } [下标]
   * @Return: null
   */
  searchTypeFn(index: number) {
    const _this: any = this;
    _this.searchIndex = index;
    let ids = ["-1", "1", "0"];
    _this.searchId = ids[index];
    _this.searchFn();
  }

  /**
   * @Author HS
   * @Date 2021/7/2 10:04 上午
   * @Description: 点击搜索记录
   * @Params: { string ： keyWord - true } [搜索关键字]
   * @Return: null
   */
  goSearch(keyWord: string) {
    const _this: any = this;
    if (_this.$base.isNull(keyWord)) return;
    _this.search = keyWord;
    _this.searchFocus = false;
    _this.searchBoolean = false;

    let params = _this.$base.serializeStr({ i: _this.searchId, s: keyWord });
    const path = _this.$base.routeLinkSplice(
      _this.$CommonConfig.search.path,
      params
    );
    _this.$base.goRouter(path);
  }

  /**
   * @Author HS
   * @Date 2021/7/2 10:03 上午
   * @Description: 删除历史搜索记录
   * @Params: { number ： index - true } [下标]
   * @Return: null
   */
  deleteHistorySearchFn(index: number) {
    const _this: any = this;
    const list: Array<string> = [..._this.historySearchList];
    _this.searchBoolean = false;
    list.splice(index, 1);
    _this.historySearchList = [...list];
    _this.searcHeight = 40 * _this.historySearchList.length;
    _this.$setEncryptBy(JSON.stringify(list), "_HISTORYSEARCHLIST");
  }

  /**
   * @Author HS
   * @Date 2021/7/2 11:25 上午
   * @Description: 购物车高度计算
   * @Params: null
   * @Return: null
   */
  shopShowFn() {
    const _this: any = this;
    const defaultHeight: number = 120;
    const btnHeight: number = 70;
    if (_this.shoppingCartListTotal > 0) {
      _this.shopHeight = defaultHeight + btnHeight;
      return;
    }
    _this.shopHeight = defaultHeight;
  }

  /**
   * @Author HS
   * @Date 2021/7/2 11:25 上午
   * @Description: 退出登录
   * @Params: null
   * @Return: null
   */
  loginOutFn() {
    const _this: any = this;
    _this.$store.dispatch("LoginOutFn", _this);
  }

  /**
   * @Author HS
   * @Date 2021/7/2 11:41 上午
   * @Description: 去登录
   * @Params: null
   * @Return: null
   */
  goLogin() {
    const _this: any = this;
    _this.$base.goRouter(_this.$LoginConfig.login.path);
  }

  /**
   * @Author HS
   * @Date 2021/7/2 3:59 下午
   * @Description: Mine页面跳转
   * @Params: { string ： path - true } [path：路由]
   * @Return:
   */
  goMine(path: string) {
    const _this: any = this;
    if (_this.$base.isLogin()) {
      const link = _this.$base.routeLinkSplice(
        _this.$LoginConfig.login.path,
        path
      );
      _this.$base.goRouter(link);
      return;
    }
    _this.$base.goRouter(_this.$MineConfig[path].path);
  }

  /**
   * @Author HS
   * @Date 2021/7/2 3:59 下午
   * @Description: Index页面跳转
   * @Params: { string ： path - true } [path：路由]
   * @Return:
   */
  goIndex(path: string) {
    const _this: any = this;
    if (_this.$base.isLogin()) {
      const link = _this.$base.routeLinkSplice(
        _this.$LoginConfig.login.path,
        path
      );
      _this.$base.goRouter(link);
      return;
    }
    _this.$base.goRouter(_this.$IndexConfig[path].path);
  }

  /**
   * @Author HS
   * @Date 2021/7/8 11:46 上午
   * @Description: 跳转到详情
   * @Params: { number ： id - true } [id]
   * @Return: null
   */
  goDetails(id: number,type:any) {
    const _this: any = this;
    const link = _this.$base.routeLinkSplice(
      _this.$IndexConfig.embroideryHomeDetail.path,
      `type=${type}&i=` + id.toString()
    );
    _this.$base.goRouter(link);
  }
}
